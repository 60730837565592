import React, { useState, useEffect } from "react";
import classNames from "classnames";
import ReCAPTCHA from "react-google-recaptcha";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import LeftLinks from "../../components/Header/LeftLinks";
import { Form, useForm } from "../Components/useForm";
import Controls from "../Components/controls/Controls";
import CustomLinearProgress from "../../components/CustomLinearProgress/CustomLinearProgress";
import './Login.css'; // Import the CSS file
import TrainingRepositiry from "repository/training-repository";



const dashboardRoutes = [];

const useStyles = makeStyles(styles);

const initialFValues = {
  inspectionFile: [],
  emailAddress: "",
  name: "",
  number: "",
  description: "",
  trainingName: "",
  dateOfTraining: "",
  district: "",
  block: "",      
  masterTrainersName: "",
  masterTrainersMobile: "",
  otherMasterTrainersName: "",
  numberOfParticipants: "",
  topicOfTraining: "",
  farmTechnologyOfficerName: "",
  chak: "",
  trainingProgram: "",
  participantImages: [],
  trainingPhotos: []
};

export default function TrainingPage(props) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [toastMessage, setToast] = useState({});

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [isTraingLogin,setTrainingLogin] = useState(false)


  useEffect(() => {
    if (navigator.geolocation) {
      const watchId = navigator.geolocation.watchPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error(`Error getting location: ${error.message}`);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );

      return () => navigator.geolocation.clearWatch(watchId);
    }
  }, []);

  

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const classes = useStyles();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("trainingName" in fieldValues)
      temp.trainingName = fieldValues.trainingName ? "" : "This field is required.";
    if ("dateOfTraining" in fieldValues)
      temp.dateOfTraining = fieldValues.dateOfTraining ? "" : "This field is required.";
    if ("district" in fieldValues)
      temp.district = fieldValues.district ? "" : "This field is required.";
    if ("block" in fieldValues)
      temp.block = fieldValues.block ? "" : "This field is required.";
    if ("masterTrainersName" in fieldValues)
      temp.masterTrainersName = fieldValues.masterTrainersName ? "" : "This field is required.";
    if ("masterTrainersMobile" in fieldValues)
      temp.masterTrainersMobile = fieldValues.masterTrainersMobile ? "" : "This field is required.";
    if ("numberOfParticipants" in fieldValues)
      temp.numberOfParticipants = fieldValues.numberOfParticipants ? "" : "This field is required.";
    if ("topicOfTraining" in fieldValues)
      temp.topicOfTraining = fieldValues.topicOfTraining ? "" : "This field is required.";
    if ("farmTechnologyOfficerName" in fieldValues)
      temp.farmTechnologyOfficerName = fieldValues.farmTechnologyOfficerName ? "" : "This field is required.";
    if ("chak" in fieldValues)
      temp.chak = fieldValues.chak ? "" : "This field is required.";
    if ("trainingProgram" in fieldValues)
      temp.trainingProgram = fieldValues.trainingProgram ? "" : "This field is required.";
    setErrors({
      ...temp
    });

    if (fieldValues === values)
      return Object.values(temp).every(x => x === "");
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleMultipleFilesUpload,
    resetForm
  } = useForm(initialFValues, true, validate);


  console.log("values",values)

  const reset = () => {
    resetForm();
    setValues(initialFValues);
    setCaptcha(null);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setOpenAlert(true);
    setToast({ type: "error", msg: "Something went wrong, please retry later!" });
    console.log("valuearee",values)
    let TrainingRepo = new TrainingRepositiry();
//     TrainingRepo.formSubmitTraining({mobileNumber : email, password}).then(data => {
//     console.log(data);
//     reset();
//     setLoading(false);
//     setOpenAlert(true);
//     setTrainingLogin(true)
//     setToast({type: "success", msg: "Your complaint has been registered!"});
// }).catch(e => {
//     console.log("Error");
//     setLoading(false);
//     setOpenAlert(true);
//     setToast({type: "error", msg: e.message});
// })
  };

  function onChange(value) {
    setCaptcha(value);
  }
  const topicOptions = [
    { id: 'topic1', name: 'Integrated Pest Management'},
    { id: 'topic2', name: 'Organic Farming' },
    { id: 'topic3', name: 'Protected cultivation and use of plasticulture technology in agriculture' },
    { id: 'topic3', name: 'Crop diversification' },
    { id: 'topic4', name: 'General management and rearing of livestock including fodder production' },
    { id: 'topic5', name: 'Village level cropping pattern, introduction of new varieties' },
    { id: 'topic6', name: 'Preservation and processing (for industrialist and businessmen' },
    { id: 'topic7', name: 'Cultivation of fruits, vegetables, spices and other horticultural crops' },
    { id: 'topic8', name: 'Economic use of irrigation water, modern water saving and irrigation techniques' },
    { id: 'topic9', name: 'Post harvest handling, packaging, transportation and storage' },
    { id: 'topic10', name: 'Modern farm machinery and tools- Strengthening of custom hire centres' },
    { id: 'topic11', name: 'Good agriculture practices to improve production and productivity' },
    { id: 'topic12', name: 'Integrated Nutrient Management (INM) and Soil Health Management' },
    // Add more districts as needed
];

const topic1 =  [
    { id: 'subtopic1', name: 'Integrated Pest Management (IPM)' },
  
  ];

  const topic2 =  [
  
    { id: 'subtopic2', name: 'Crop Rotation' },
    { id: 'subtopic2', name: 'Vermicomposting' },
    { id: 'subtopic2', name: 'Compost' },
    { id: 'subtopic2', name: 'Compost' },
  ];
  const topic3 =  [
  
    { id: 'subtopic3', name: 'Protected cultivation and use of plastic in agriculture' },
   
  
  ];
  const topic4 =  [
  
    { id: 'subtopic4', name: 'Crop diversification and introduction of new crops in the area' },
   
  
  ];
  
   
  

  
  
  const districtOptions = [
    { id: 'district1', name: 'Hanumangarh' },
    { id: 'district2', name: 'Anoopgarh' },
    { id: 'district3', name: 'Bikaner' },
    { id: 'district4', name: 'sri ganganagrgar' },
    // Add more districts as needed
];



const distric1 = [
    { id: 'block1', name: 'Hanumangarh' },
    { id: 'block1', name: 'Nohar' },
    { id: 'block1', name: 'Pilibanga'},
    { id: 'block1', name: 'Rawatsar'},
    { id: 'block1', name: 'Sangaria'},
    { id: 'block1', name: 'Tibbi'},
    { id: 'block1', name: 'Bhadra'},
    // Add more blocks as needed
];
const distric2 = [
  { id: 'block2', name: 'Anupgarh' },
  { id: 'block2', name: 'Vijaynagar' },
  { id: 'block2', name: 'Raisinghnagar'},
  { id: 'block2', name: 'Raisinghnagar'},
 
  // Add more blocks as needed
];

     //     let complaintRepo = new ComplaintRepository();
        //     complaintRepo.submitComplaint(formData).then(data => {
        //         console.log(data);
        //         reset();
        //         setLoading(false);
        //         setOpenAlert(true);
        //         setToast({type: "success", msg: "Your complaint has been registered!"});
        //     }).catch(e => {
        //         console.log("Error");
        //         setLoading(false);
        //         setOpenAlert(true);
        //         setToast({type: "error", msg: e.message});
        //     })
        // }


        const { ...rest } = props;


        const [email, setEmail] = useState('');
        const [password, setPassword] = useState('');
      
        const handleSubmitLogin = (e) => {
          e.preventDefault();
          // Handle login logic
          console.log('Email:', email);
          console.log('Password:', password);
                let TrainingRepo = new TrainingRepositiry();
                TrainingRepo.loginTraining({mobileNumber : email, password}).then(data => {
                console.log(data);
                reset();
                setLoading(false);
                setOpenAlert(true);
                setTrainingLogin(true)
                setToast({type: "success", msg: "Your complaint has been registered!"});
            }).catch(e => {
                console.log("Error");
                setLoading(false);
                setOpenAlert(true);
                setToast({type: "error", msg: e.message});
            })
        
        };
      


  const rendorLoginForm = () => {
    return (
      <div className="login-container">
        <div className="login-box">
          <h3 className="login-title">Login</h3>
          <form onSubmit={handleSubmitLogin}>
            <div className="form-group">
              <label htmlFor="email">Email address</label>
              <input
                type="text"
                id="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="login-btn">Login</button>
          </form>
        </div>
      </div>
    )
  }



  const rendertrainingForm =() => {
    return (
    
   
        <div className={classNames(classes.main, classes.mainRaised)} style={{ margin: 0 }}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.center} justify={"center"}>
                <h3 className={classes.title} style={{ color: "black" }}>One day trainings organized by DoA & sponsor by WRD, under RWSRPD</h3>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.center}>
                <Form onSubmit={handleSubmit}>
                  {
                    loading && <CustomLinearProgress />
                  }
                  <Grid container alignItems={"center"} justify={"center"}>
                    <Grid item style={{ width: "100%" }}>
                      
                    <Controls.Input
    name="trainingName"
    label={<span style={{ fontWeight: 'bold' }}>Program:- Agriculture Intensification & Diversification trainings</span>}
    value={values.trainingName}
    onChange={handleInputChange}
    disabled={true}
    error={errors.trainingName}
  />
  <Controls.Select
              label="Topic"
              name="topic"
              value={values.topic}
              onChange={handleInputChange}
              disabled={loading}
              error={errors.topic}
              options={topicOptions}
          />
           {values.topic && (
      <Controls.Select
        label="Subtopic"
        name="subtopic"
        value={values.subtopic}
        onChange={handleInputChange}
        disabled={loading}
        error={errors.subtopic}
        options={ values?.topic === "topic1"
          ? topic1
          : values.topic === "topic2"
          ? topic2
          : values.topic === "topic3"
          ? topic3
          : values.topic === "topic4"
          ? topic4
          
          :   []}
      />
    )}
  
  
  
  
                      <Controls.Input
                      //   label="Date of Training"
                        name="dateOfTraining"
                        value={values.dateOfTraining}
                        type="date"
                        required
                        onChange={handleInputChange}
                        disabled={loading}
                        error={errors.dateOfTraining}
                      />
                     
  
  <Controls.Select
              label="District"
              name="district"
              value={values.district}
              onChange={handleInputChange}
              disabled={loading}
              error={errors.district}
              options={districtOptions}
          />
     
      {values.district && (   
          <Controls.Select
              label="Block"
              name="block"
              value={values.block}
              onChange={handleInputChange}
              disabled={loading}
              error={errors.block}
              options={values.district === "district1" ? distric1 : distric2|| []}
          />
        )}        <Controls.Input
                        label="Master Trainers' Name"
                        name="masterTrainersName"
                        value={values.masterTrainersName}
                        required
                        onChange={handleInputChange}
                        disabled={loading}
                        error={errors.masterTrainersName}
                      />
                      <Controls.Input
                        label="Master Trainers' Mobile Number"
                        name="masterTrainersMobile"
                        value={values.masterTrainersMobile}
                        required
                        type="tel"
                        onChange={handleInputChange}
                        disabled={loading}
                        error={errors.masterTrainersMobile}
                      />
                      <Controls.Input
                        label="Other Trainers Name"
                        name="otherMasterTrainersName"
                        value={values.otherMasterTrainersName}
                        onChange={handleInputChange}
                        disabled={loading}
                        error={errors.otherMasterTrainersName}
                      />
                   
             
                      <Controls.Input
                        label="	Owner of Farmer Technology Center (FTC)"
                        name="farmTechnologyOfficerName"
                        value={values.farmTechnologyOfficerName}
                        required
                        onChange={handleInputChange}
                        disabled={loading}
                        error={errors.farmTechnologyOfficerName}
                      />
  
  <Controls.Input
                        label="	Mobile Number of Farmer Technology Center (FTC)/owner"
                        name="mobileOfFTC"
                        value={values.mobileOfFTC}
                        required
                        onChange={handleInputChange}
                        disabled={loading}
                        error={errors.mobileOfFTC}
                      />
                      <Controls.Input
                        label="Chak Number"
                        name="chak"
                        value={values.chak}
                        required
                        onChange={handleInputChange}
                        disabled={loading}
                        error={errors.chak}
                      />
   <Controls.Input
                        name="latitude"
                        label="Latitude"
                        value={latitude || ''}
                        onChange={(e) => setLatitude(e.target.value)}
                        disabled
                      />
                      <Controls.Input
                        name="longitude"
                        label="Longitude"
                        value={longitude || ''}
                        onChange={(e) => setLongitude(e.target.value)}
                        disabled
                      /> 
                      <Controls.Input
                        label="Number of Participants"
                        name="numberOfParticipants"
                        value={values.numberOfParticipants}
                        required
                        type="number"
                        onChange={handleInputChange}
                        disabled={loading}
                        error={errors.numberOfParticipants}
                      />
  
  
  
  
  <Controls.Input
                        label="Description on training"
                        name="descriptionOnTraining"
                        value={values.descriptionOnTraining}
                        required
                        type="text"
                        onChange={handleInputChange}
                        disabled={loading}
                        descriptionOnTraining                      />
                     
                     <Controls.Input
                        label="Additional topic/subject cover on traning  "
                        name="additionalTopics"
                        value={values.additionalTopics}
                        required
                        type="text"
                        onChange={handleInputChange}
                        disabled={loading}
                        error={errors.additionalTopics}
                      />
                    
                    {/* <Controls.Input
                        label="Outcome/Training Benefit/Feedback"
                        name="numberOfParticipants"
                        value={values.numberOfParticipants}
                        required
                        type="number"
                        onChange={handleInputChange}
                        disabled={loading}
                        error={errors.numberOfParticipants}
                      /> */}
  
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {/* Number Input Field */}
    <Controls.Input
      label="Outcome/Training Benefit/Feedback"
      name="outcomeTrainingBenefit"
      value={values.outcomeTrainingBenefit}
      required
      type="text"
      onChange={handleInputChange}
      disabled={loading}
      error={errors.outcomeTrainingBenefit}
      style={{ flexGrow: 1 }} // This makes the input field take up available space
    />
  
    {/* Image Upload Field */}
    <input
      type="file"
      accept="image/*"
      onChange={(e) => handleInputChange(e, "uploadedImage")}
      disabled={loading}
      style={{ marginLeft: '10px' }}
    />
  </div>
  
  
  
  
  <div style={{ display: 'flex', gap: '72px' }}>
    <Controls.UploadFile
      name="participantImages"
      label="Upload list of Attendance"
      title="Upload Participants' Images"
      value={values.participantImages}
      disabled={loading}
      onChange={handleMultipleFilesUpload}
      dialogProps={{
        acceptedFiles: [".jpg", ".jpeg", ".png"],
        showPreviews: true,
        maxFileSize: 2 * 1024 * 1024,
        filesLimit: 5,
      }}
    />
    <Controls.UploadFile
      name="trainingPhotos"
      label="Upload 2 Photographs of Training"
      title="Upload Photographs"
      value={values.trainingPhotos}
      disabled={loading}
      onChange={handleMultipleFilesUpload}
      dialogProps={{
        acceptedFiles: [".jpg", ".jpeg", ".png"],
        showPreviews: true,
        maxFileSize: 2 * 1024 * 1024,
        filesLimit: 2,
      }}
    />
  </div>
  
                    </Grid>
                    <Grid item>
                      <Button
                        color={"success"}
                        type="submit"
                        disabled={loading || !captcha}
                        onClick = {() => console.log(window.location)}
                        style={{ marginTop: "20px",marginRight: "160px", marginBottom : "80px" }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                 
                </Form>
              </GridItem>
            </GridContainer>
          </div>
        </div>
       
    );
  }


  return(
    <div>
    <Header
      color="dark"
      brand={"Water Resource Department - Govt of Rajasthan"}
      rightLinks={<HeaderLinks />}
      sticky
    />
    <Header
      color="dark"
      routes={dashboardRoutes}
      sticky
      top={"70px"}
      leftLinks={<LeftLinks />}
      mobileOpen={mobileOpen}
      onDrawerToggle={handleDrawerToggle}
      {...rest}
    />
    <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
      <Alert onClose={handleAlertClose} severity={toastMessage.type}>
        {toastMessage.msg}
      </Alert>
    </Snackbar>
    {isTraingLogin ?  rendertrainingForm() : rendorLoginForm() }
    <Footer />
    </div>
  )
  
}
